<template>
  <a-card class="card" :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">
          <a-col :md="8" :sm="24">
            <a-form-item label="姓名">
              <a-input v-model="queryParam['%name']"/>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="query" icon="search" @click="$refs.table.refresh(true)">查询</a-button>
      <a-button type="primary" icon="plus" @click="handleCreate">新建</a-button>
      <a-button type="danger" icon="delete" @click="handleDelete('all')" :disabled="!this.selectedRowKeys.length">删除</a-button>
    </div>

    <s-table
      ref="table"
      size="default"
      rowKey="id" :scroll="{ x: 1000 }"
      :columns="columns"
      :data="loadData"
      :rowSelection="{ selectedRowKeys: this.selectedRowKeys, onChange: this.onSelectChange }"
      @dblclick="handleEdit">
      <span slot="serial" slot-scope="text, record, index">
        {{ index + 1 }}
      </span>
      <span slot="action" slot-scope="text, record, index">
        <template>
          <a @click="handleEdit(record, index)">编辑</a>
          <a-divider type="vertical" />
          <a @click="handleDelete('row', record)">删除</a>
        </template>
      </span>
    </s-table>

    <a-modal
      :visible="formVisible" :confirmLoading="confirmLoading"
      :title="modalFlag === 'create' ? '创建司机' : '编辑司机'"
      :width="720" @cancel="formVisible = false"
      @ok="handleSubmit"
    >
      <a-form-model layout="vertical" :model="form"
                    :rules="rules" ref="form">
        <a-row :gutter="20">
          <a-col :span="8">
            <a-form-model-item label="姓名" prop="name">
              <a-input v-model="form.name" @change="handleNameChange" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="简称">
              <a-input v-model="form.abbr" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="所属车队">
              <a-select v-model="form.fleet_id" showSearch
                        ref="fleet"
                        allowClear style="width: 100%">
                <a-select-option v-for="o in fleetOps" :key="o.value">{{ o.name }}</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="手机号">
              <a-input v-model="form.mobile" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="驾驶证号">
              <a-input v-model="form.license_no" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="身份证号">
              <a-input v-model="form.identity_id" />
            </a-form-model-item>
          </a-col>
          <a-col :span="8">
            <a-form-model-item label="驾驶证有效期">
              <a-date-picker v-model="form.license_expiry" class="full-width"/>
            </a-form-model-item>
          </a-col>
          <a-col :span="16">
            <a-form-model-item label="现住地址">
              <a-input v-model="form.address" />
            </a-form-model-item>
          </a-col>
        </a-row>
        <a-form-model-item label="家庭住址">
          <a-input v-model="form.home_address" />
        </a-form-model-item>
        <a-form-model-item label="备注">
          <a-textarea v-model="form.remark" placeholder="" :rows="3" />
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </a-card>
</template>

<script>
import { STable } from '@/components'
import moment from 'moment';
import { getFirstLetter } from '@/utils/util'
import { getMetaInfoPage, getMetaOptions, deleteMetaTypeInfo, createMetaTypeInfo, updateMetaTypeInfo } from '@/api/wms/meta'
export default {
  components: {
    STable
  },
   data() {
    return {
      queryParam: {},
      columns: [
        {
          title: '#',
          scopedSlots: { customRender: 'serial' },
          width: 30
        },
        {
          title: '姓名',
          dataIndex: 'name',
          width: 60
        },
        {
          title: '简称',
          dataIndex: 'abbr',
          width: 60
        },
        {
          title: '所属车队',
          dataIndex: 'fleet_name',
          width: 100
        },
        {
          title: '手机号',
          dataIndex: 'mobile',
          width: 120
        },
        {
          title: '驾驶证号',
          dataIndex: 'license_no',
          width: 120
        },
        {
          title: '身份证号',
          dataIndex: 'identity_id',
          width: 120
        },
        {
          title: '驾驶证有效期',
          dataIndex: 'license_expiry',
          width: 120
        },
        {
          title: '现住地址',
          dataIndex: 'address',
          width: 100
        },
        {
          title: '家庭住址',
          dataIndex: 'home_address',
          width: 100
        },
        {
          title: '备注',
          dataIndex: 'remark',
          width: 100
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: 120,
          fixed: 'right',
          scopedSlots: { customRender: 'action' }
        }
      ],
      selectedRowKeys: [],
      loadData: parameter => {
        return getMetaInfoPage('driver', Object.assign(parameter, this.queryParam))
          .then(res => {
            return res
          })
      },
      fleetOps: [],
      form: {
        name: '',
        abbr: '',
        fleet_id: '',
        fleet_name: '',
        mobile: '',
        license_no: '',
        identity_id: '',
        license_expiry: null,
        address: '',
        home_address: '',
        remark: ''
      },
      rules: {
        name: [
          { required: true, message: '请输入姓名', trigger: 'blur' }
        ]
      },
      currentData: null,
      formVisible: false,
      confirmLoading: false,
      modalFlag: 'create'
    }
  },
  methods: {
    moment,
    handleCreate() {
      this.modalFlag = 'create'
      this.form = {
        name: '',
        abbr: '',
        fleet_id: '',
        fleet_name: '',
        mobile: '',
        license_no: '',
        identity_id: '',
        license_expiry: null,
        address: '',
        home_address: '',
        remark: ''
      }
      this.currentData = null
      this.getFormBasicData()
      this.formVisible = true
      this.$nextTick(_ => {
        this.$refs.form.resetFields();
      })
    },
    handleNameChange() {
      this.form.abbr = getFirstLetter(this.form.name)
    },
    handleEdit(record) {
      this.modalFlag = 'update'
      this.getFormBasicData()
      this.formVisible = true
      this.currentData = record
      this.form = { ...record }
      this.form.license_expiry = this.form.license_expiry ? moment(this.form.license_expiry, 'YYYY/MM/DD') : null
    },
    getFormBasicData() {
      getMetaOptions('fleet').then(v => {
        this.fleetOps = v
      })
    },
    handleDelete(flag, record) {
      this.$confirm({
        title: '确认提示',
        content: '确定要删除？',
        onOk: () => {
          let _ids = []
          if (flag === 'all') {
            _ids = this.selectedRowKeys
          } else {
            _ids = [record.id]
          }
          deleteMetaTypeInfo('driver', { ids: _ids }).then(_ => {
            this.$notification['success']({
              message: '提示',
              description: '删除成功.'
            })
            this.selectedRowKeys = [];
            this.$refs.table.refresh(true)
          })
        }
      })
    },
    handleSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          let func = createMetaTypeInfo
          if (this.modalFlag === 'update') {
            func = updateMetaTypeInfo
          }
          const data = { ...this.form,
            fleet_name: this.$refs['fleet'].$el.innerText,
            license_expiry: this.form.license_expiry ? moment(this.form.license_expiry).format('L') : null
          }
          if (this.currentData) {
            Object.assign(this.currentData, data)
          }
          func('driver', data).then(_ => {
              this.confirmLoading = false
              this.formVisible = false
              this.$notification['success']({
                message: '提示',
                description: this.modalFlag === 'update' ? '编辑成功' : '创建成功.'
              })
              if (this.modalFlag === 'create') {
                this.$refs.table.refresh(true)
              }
            }).catch(_ => {
              this.formVisible = false
              this.confirmLoading = false
            })
        } else {
          return false;
        }
      });
    },
    onSelectChange (selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
    }
  }
}
</script>
